#pages-container {
    padding: 20px 20px 0 20px;
    height: 100%;
    position: relative;
    left: 0;
    width: 100%;
    overflow: auto;
    background-color: #111;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 90px;
}

.page {
    background-size: contain;
    background-position: top left;
    background-repeat: no-repeat;
    min-width: 816px;
    width: 816px;
    min-height: 816px;
    height: 1056px;
    position: relative;
    margin: 0 10px 10px 10px;
}

.editor {
    resize: none;
    border: none;
    box-sizing: border-box;
    overflow: hidden;
    transform-origin: top left;
    transform: scale(1);
    position: relative;
    background-color: rgba(0, 0, 0, 0);
    color: black;
    outline: 0;
}

@media (max-width: 900px) {
    #pages-container{
        justify-content: flex-start;
    }    
}