:root {
  --ui-border-color: #314354;
  --ohif-toolbar-height: 78px; /* default */
}

.ToolbarRow {
  height: initial !important;
  min-height: var(--toolbar-height);
  flex-flow: wrap;
}

.FlexboxLayout {
  height: calc(100vh - var(--ohif-toolbar-height) - 64px) !important;
}

@media screen and (max-width: 768px) {
  .FlexboxLayout {
    height: calc(100vh - var(--ohif-toolbar-height) - 56px) !important;
  }
}


@media screen and (max-width: 768px) {
  #side-report{
    display: none;
  }
}