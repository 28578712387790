@import './styles/index';

html,
body {
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

#main-content-layout {
  flex-grow: 1;
}

a {
  text-decoration: none;
}

.ohif-custom-button__movile {
  display: none !important;
}

@media (max-width: 900px) {
  .ohif-custom-button__desktop {
    display: none !important;
  }
  .ohif-custom-button__movile {
    display: block !important;
  }
}
